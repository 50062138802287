import React from 'react';
import '../App.css';
import './DroneSection.css';
import VideoCardItem from './VideoCardItem';

function DroneSection() {
  return (
    <div className='drone-container'>
      <h1 className='drone'>Drone Services</h1>
      <img className='gallery-intro-photo' src='./images/Andrew/andrew_drone_1.jpg' alt='Andrew with DJI Air 2S' />
  <p> I hold a Part 107 license from the FAA for use as a commercial drone pilot. I offer many services, including but not limited to:
      <ul>
          <li>Real estate photography and videography</li>
          <li>Weddings, concerts, or other events</li>
          <li>Roof, powerline, farm, or industrial inspections</li>
          <li>Cinematography</li>
      </ul>
    </p>
    <h2 className='realestate'>Real Estate</h2>
    <VideoCardItem
              src='./images/drone/thumbnails/real_estate_video_1.jpg'
              text='Charming Magnolia Home'
              href='https://www.youtube.com/watch?v=6dYvys67aZc'
            />
    <h2 className='nature'>Nature</h2>
    <div className='nature_cards__container'>
        <div className='nature_cards__wrapper'>
          <ul className='nature_cards__items'>
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_10.jpg'
              text='Hawaii'
              href='https://www.youtube.com/watch?v=QpYKcXEe_RE'
            />
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_1.jpg'
              text='Mt Baker, Washington'
              href='https://www.youtube.com/watch?v=fUGu0fKBcCI'
            />
          </ul>
          <ul className='nature_cards__items'>
            <VideoCardItem
                src='./images/drone/thumbnails/drone_landscape_4.jpg'
                text='Heceta Lighthouse, Oregon'
                href='https://www.youtube.com/watch?v=y2ZHPzOVUNM'
              />
              <VideoCardItem
                src='./images/drone/thumbnails/drone_landscape_2.jpg'
                text='San Juan Island, Washington'
                href='https://www.youtube.com/watch?v=4Q7zahHacuQ'
              />
          </ul>
          <ul className='nature_cards__items'>
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_3.jpg'
              text='Sanibel Island, Florida'
              href='https://www.youtube.com/watch?v=JpymsXEehBw'
            />
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_5.jpg'
              text='Discovery Park, Seattle, Washington'
              href='https://www.youtube.com/watch?v=L6_5uhuukRU'
            />
          </ul>
          <ul className='nature_cards__items'>
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_7.jpg'
              text='Bainbridge Island, Washington'
              href='https://www.youtube.com/watch?v=p6Xjp5A62Mo'
            />
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_6.jpg'
              text='Deception Pass, Washington'
              href='https://www.youtube.com/watch?v=pDTwGf13ZTQ'
            />
          </ul>
          <ul className='nature_cards__items'>
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_8.jpg'
              text='Center Hill Lake, Tennessee'
              href='https://www.youtube.com/watch?v=53-W7cd1IyI'
            />
            <VideoCardItem
              src='./images/drone/thumbnails/drone_landscape_9.jpg'
              text='Douglas Lake, Tennessee'
              href='https://www.youtube.com/watch?v=pEOezx3BoAg'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DroneSection;