import React from 'react';
import '../../App.css';
import LandscapesSection from '../LandscapesSection';
import Footer from '../Footer';

export default function Landscapes() {
  return (
  <>
     <LandscapesSection />
      <Footer />
  </>
  );
}
