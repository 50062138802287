import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div>
          <p class='website-rights-text'> © 2023 Andrew Davidson. All rights reserved.</p>
          <p class='website-rights-text'> Seattle, Washington, USA</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
