import React from 'react';
import '../../App.css';
import DroneSection from '../DroneSection';
import Footer from '../Footer';

export default function Drone() {
  return (
  <>
     <DroneSection />
      <Footer />
  </>
  );
}
