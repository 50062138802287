import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Products &amp; Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <ul className='cards__items__items'>
              <CardItem
                src='./images/drone/thumbnails/real_estate_1.jpg'
                text='Drone Services'
                path='/drone-services'
                alt='Drone Services'
              />
              <CardItem
                src='./images/portraits/thumbnails/Swan_Trail_9_26_2021_Sunflowers_8.jpg'
                text='Portraits'
                path='/portraits'
                alt='Portraits'
              />
            </ul>
            <ul className='cards__items__items'>
              <CardItem
                src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1582_D.jpg'
                text='Landscape Prints'
                path='/landscapes'
                alt='Landscape Prints'
              />
                <CardItem
                src='./images/wildlife/thumbnails/Enchantments_2021_266.jpg'
                text='Wildlife Prints'
                path='/wildlife'
                alt='Wildlife Prints'
              />
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
