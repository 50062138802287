import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            Home
            <img src="./mint-logo-64.png" class='fab fa-typo3' alt='Logo' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                Contact
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/drone-services' className='nav-links' onClick={closeMobileMenu}>
                Drone
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/portraits'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Portraits
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/landscapes' className='nav-links' onClick={closeMobileMenu}>
                Landscapes
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/wildlife' className='nav-links' onClick={closeMobileMenu}>
                Wildlife
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/questions'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Q &amp; A
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;