import React from 'react';
import '../../App.css';
import ContactSection from '../ContactSection';
import Footer from '../Footer';

export default function Contact() {
    return (
        <>
     <ContactSection />
       <Footer />
        </>
      );
}
