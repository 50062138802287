import React from 'react';
import '../App.css';
import './QuestionsSection.css';

function QuestionsSection() {
  return (
    <div className='questions-container'>
        <h1 className='questions'>Q &amp; A</h1>

        <h3>  Do you offer prints of photographs? </h3>
        <p>  Yes! However, if you are purchasing personal portraits, the photographs are delivered in a digital form as well, so you can arrange to have them printed elsewhere. </p>

        <h3> Do you offer the original RAW version of photographs?  </h3>
        <p>  Yes, upon request.  </p>

        <h3>  Are your drone flights insured? </h3>
        <p>  Yes. All flights are insured by SkyWatch for liable damages up to $2M. </p>

        <h3>Can you fly your drone in the rain? </h3>
        <p> No. If I'm uncomfortable with pre-flight weather conditions, I may cancel.  In this case, you may choose to reschedule or receive a refund for your deposit.  </p>

        <h3> Are you able to fly your drone in neighborhoods that are near an airport?  </h3>
        <p>  Yes, in most cases.  I can obtain LAANC authorization instantaneously and automatically in all but the closest neighborhoods to an airport.  This comes at no additional charge.  </p>

        <h3>  Can you fly your drone indoors? </h3>
        <p>  Yes, although 3D virtual tours may be a better alternative. Drones are great at capturing images from high and hard-to-reach places, but are difficult to fly in small spaces such as private residences. Precise movements are difficult, and the airflow from a drone's propellers disrupts its own flight, causing unstable video footage.  </p>

        <h3>  Can you fly at sunset or at night? </h3>
        <p>  Yes.  My drone is equipped with a strobe that meets FAA requirements of visibility from 3 Statute Miles.</p>

        <h3> Are you a certified survey technician? </h3>
        <p> No. My drone cannot be used for precise architectural surveys. </p>
        <br></br>
    </div>
  );
}

export default QuestionsSection;