import React from 'react';
import '../App.css';
import './ContactSection.css';

function ContactSection() {
  return (
    <div className='contact-container'>
      <h1 className='contact'>Contact Me</h1>
      <h3>+1 (971) 245-4309</h3>
      <h2><a href="mailto:andrewdavidsonphotovideo@gmail.com">andrewdavidsonphotovideo@gmail.com</a></h2>
      <h3><a href="https://www.facebook.com/AndrewDavidsonPhotoVideo">Facebook</a></h3>
      <h3>Seattle, WA</h3>
    </div>
  );
}

export default ContactSection;