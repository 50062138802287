import React from 'react';
import '../App.css';
import './AboutSection.css';

function AboutSection() {
  return (
    <div className='about-container'>
      <h1 className='about'>About Me</h1>
      <img className='about-profile-photo' src='./images/Andrew/andrew_1.jpg' alt='Andrew with camera and gimbal hiking the Grand Canyon' />
      <p>My name is Andrew Davidson.  I am a photographer, videographer, and commercial drone pilot based in the Seattle area.  I have years of experience as a hobbyist landscape photographer, and have recently expanded to portrait photography and drone videography.
      </p>
      <p>I have a burning passion for photography that I'd like to share! As such, your satisfaction is my priority!
      </p>
      <h2 className='equipment'>My Equipment</h2>
      <h3>Adobe Suite</h3>
      <p>I use the Adobe suite to process photos and videos to ensure the highest-quality results.  I use Lightroom and Photoshop for processing photographs, and I use Premiere Pro for video editing.
      </p>
      <h3>DJI Air 2S Drone</h3>
      <p>The DJI Air 2S has a 1-inch CMOS sensor that shoots video in 5.4K at 30 frames per second or 4K at 60 frames per second. It is also capable of shooting 20 MP RAW still photographs. It uses state-of-the-art software and a 3-axis gimbal to shoot incredibly smooth footage even in high winds. It also uses ADS-B to detect when a manned aircraft may be approaching to avoid dangers of collision.  And don't worry! The propeller guards prevent crashes and property damage. 
      </p>
      <p>My drone is also equipped with a strobe light visible for 3 miles, meeting legal requirements for night flying.
      </p>
      <h3>Panasonic S5 Camera</h3>
      <p>My Panasonic S5 is a versatile, cutting-edge mirrorless camera that excels in both photography and videography. It has a 24.2MP full-frame sensor. My lenses consist of a Panasonic 24-105mm (Macro, wide angle, portraits) and a Sigma 150-600mm (portraits, sports, wildlife).
      </p>
      <p>For portrait photography, I use a portable light reflector, an off-camera flash stand with an umbrella, and a diverse collection of lens filters. 
      </p>
      <p>For videography, I use a DJI Ronin SC2 gimbal for stable footage, and a hybrid shotgun/stereo Azden microphone.  Best of all, the Ninja V external recorder uses portable SSDs to record 5.9K 12-bit RAW video at 30 fps. 
      </p>
      <p>As any good photographer should, I expect the unexpected and always bring ample amounts of batteries and memory cards.
      </p>
      <br></br>
    </div>
  );
}

export default AboutSection;
