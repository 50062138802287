import React from 'react';
import '../../App.css';
import WildlifeSection from '../WildlifeSection';
import Footer from '../Footer';

export default function Wildlife() {
  return (
  <>
     <WildlifeSection />
      <Footer />
  </>
  );
}
