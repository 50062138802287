import React from 'react';
import '../App.css';
import './WildlifeSection.css';

function WildlifeSection() {
  return (
    <div className='wildlife-container'>
      <h1 className='wildlife'>Wildlife</h1>
      <img className='gallery-intro-photo' src='./images/Andrew/Maple_Pass_Andrew.jpg' />
      <h2 className='gallery'>Gallery</h2>
      <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Manuel_Antonio_411-E.jpg' >
        <img className='gallery-highlight-photo gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Manuel_Antonio_411-E.jpg' />
      </a>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/Enchantments_2021_266.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/Enchantments_2021_266.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/Enchantments_2021_219.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/Enchantments_2021_219.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Jaco_Macaw_Nest_First_Attempt_823.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Jaco_Macaw_Nest_First_Attempt_823.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Jaco_Macaw_Nest_First_Attempt_790.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Jaco_Macaw_Nest_First_Attempt_790.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/Mt_Rainier_8_1_2022_362.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/Mt_Rainier_8_1_2022_362.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/Olympic_Peninsula_8_2022_25_D.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/Olympic_Peninsula_8_2022_25_D.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Nortico_Chocolate_Farm_1782.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Nortico_Chocolate_Farm_1782.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1149.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1149.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tortuguero_171-E.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tortuguero_171-E.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tortuguero_143.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tortuguero_143.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2022_10_Hawaii_Punaluu_Beach_Turtles_9.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2022_10_Hawaii_Punaluu_Beach_Turtles_9.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2022_10_Hawaii_Punaluu_Beach_Turtles_224.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2022_10_Hawaii_Punaluu_Beach_Turtles_224.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1144.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1144.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1116.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1116.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2022_10_Hawaii_Waikoloa_Beach_Mongooses_868-E.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2022_10_Hawaii_Waikoloa_Beach_Mongooses_868-E.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tortuguero_55.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tortuguero_55.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Arenal_Penas_Blancas_River_Raft_Tour_890.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Arenal_Penas_Blancas_River_Raft_Tour_890.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Arenal_Penas_Blancas_River_Raft_Tour_989.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Arenal_Penas_Blancas_River_Raft_Tour_989.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1494.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1494.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1671.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1671.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tortuguero_122.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tortuguero_122.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1454.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1454.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1180.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1180.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1041.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1041.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Arenal_Penas_Blancas_River_Raft_Tour_1023.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Arenal_Penas_Blancas_River_Raft_Tour_1023.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tortuguero_29.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tortuguero_29.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1487.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1487.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1079.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1079.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1128.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1128.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1068.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1068.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1560.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1560.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1625.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1625.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1470.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1470.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1502.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1502.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1647.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1647.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tarcoles_River_Tour_1650.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tarcoles_River_Tour_1650.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/Florida_2021_South_Beach_71.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/Florida_2021_South_Beach_71.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/Florida_2021_South_Beach_107.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/Florida_2021_South_Beach_107.jpg' />
        </a>
      </ul>
      <ul className='wildlife-row'>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1050.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1050.jpg' />
        </a>
        <a target="_blank" href='./images/wildlife/full/2023_Costa_Rica_Tortuguero_230.jpg' >
          <img className='gallery-photo' src='./images/wildlife/thumbnails/2023_Costa_Rica_Tortuguero_230.jpg' />
        </a>
      </ul>
    </div>
  );
}

export default WildlifeSection;

