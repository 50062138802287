import React from 'react';
import '../App.css';
import './PortraitsSection.css';

function PortraitsSection() {
  return (
    <div className='portraits-container'>
      <h1 className='portraits'>Portraits</h1>
      <img className='gallery-intro-photo' src='./images/Andrew/shoot_4.jpg' />
      <h2 className='gallery'>Gallery</h2>
      <a target="_blank" href='./images/portraits/full/Hawaii_wedding_2.jpg' >
        <img className='gallery-highlight-photo gallery-photo' src='./images/portraits/thumbnails/Hawaii_wedding_2.jpg' />
      </a>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/Hawaii_wedding_1.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Hawaii_wedding_1.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/Kerry_Park_Girl.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Kerry_Park_Girl.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/2023_Costa_Rica_Avellanas_Beach_1701-E.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/2023_Costa_Rica_Avellanas_Beach_1701-E.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/surfer_4_F.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/surfer_4_F.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/2022_10_Hawaii_Laupahoehoe_Point_Girl_66.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/2022_10_Hawaii_Laupahoehoe_Point_Girl_66.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/2023_4_9_UW_Cherry_Blossoms_Girl_119.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/2023_4_9_UW_Cherry_Blossoms_Girl_119.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/blueberry_1.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/blueberry_1.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/2023_Costa_Rica_Tamarindo_Estuary_Tour_1178.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/2023_Costa_Rica_Tamarindo_Estuary_Tour_1178.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/Kerry_Park_8_2022_30.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Kerry_Park_8_2022_30.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/Mt_Rainier_8_1_2022_188.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Mt_Rainier_8_1_2022_188.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/2022_10_2_Cutthroat_Lake_Girl_89.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/2022_10_2_Cutthroat_Lake_Girl_89.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/Mt_Baker_8_21_22_9.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Mt_Baker_8_21_22_9.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/Swan_Trail_9_26_2021_Sunflowers_8.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Swan_Trail_9_26_2021_Sunflowers_8.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/Swan_Trail_9_26_2021_Sunflowers_40.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Swan_Trail_9_26_2021_Sunflowers_40.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/snoqualmie_2.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/snoqualmie_2.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/blossoms_2.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/blossoms_2.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/2023_4_9_UW_Cherry_Blossoms_Girl_217.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/2023_4_9_UW_Cherry_Blossoms_Girl_217.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/2023_4_9_UW_Cherry_Blossoms_Girl_182-F.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/2023_4_9_UW_Cherry_Blossoms_Girl_182-F.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/Swan_Trail_9_26_2021_Apples_78.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Swan_Trail_9_26_2021_Apples_78.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/blossoms_1.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/blossoms_1.jpg' />
      </a>
      </ul>
      <ul className='portrait-row'>
      <a target="_blank" href='./images/portraits/full/mountain_friend.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/mountain_friend.jpg' />
      </a>
      <a target="_blank" href='./images/portraits/full/Strawberries_1.jpg' >
        <img className='gallery-photo' src='./images/portraits/thumbnails/Strawberries_1.jpg' />
      </a>
      </ul>
    </div>
  );
}

export default PortraitsSection;