import React from 'react';
import '../../App.css';
import PortraitsSection from '../PortraitsSection';
import Footer from '../Footer';

export default function Portraits() {
  return (
  <>
     <PortraitsSection />
      <Footer />
  </>
  );
}
