import React from 'react';

function VideoCardItem(props) {
  return (
    <>
      <li className='video_cards__item'>
        <a className='video_cards__item__link' target="_blank" rel="noreferrer" href={props.href} >
          <figure className='video_cards__item__pic-wrap' data-category={props.label}>
            <img
              className='video_cards__item__img'
              src={props.src}
              alt={props.alt}
            />
          </figure>
          <div className='video_cards__item__info'>
            <h5 className='video_cards__item__text'>{props.text}</h5>
          </div>
        </a>
      </li>
    </>
  );
}

export default VideoCardItem;
