import React, { Fragment } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Questions from './components/pages/Questions';
import Drone from './components/pages/Drone';
import Portraits from './components/pages/Portraits';
import Landscapes from './components/pages/Landscapes';
import Wildlife from './components/pages/Wildlife';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Fragment>
          <ScrollToTop />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/about' component={About} />
            <Route path='/contact' component={Contact} />
            <Route path='/questions' component={Questions} />
            <Route path='/drone-services' component={Drone} />
            <Route path='/portraits' component={Portraits} />
            <Route path='/landscapes' component={Landscapes} />
            <Route path='/wildlife' component={Wildlife} />
          </Switch>
        </Fragment>
      </Router>
    </>
  );
}

export default App;
