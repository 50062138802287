import React from 'react';
import '../../App.css';
import QuestionsSection from '../QuestionsSection';
import Footer from '../Footer';

export default function Questions() {
  return (
    <>
   <QuestionsSection />
   <Footer />
    </>
  );
}
