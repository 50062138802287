import React from 'react';
import '../../App.css';
import AboutSection from '../AboutSection';
import Footer from '../Footer';

export default function About() {
  return (
  <>
     <AboutSection />
      <Footer />
  </>
  );
}
