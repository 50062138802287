import React from 'react';
import '../App.css';
import './LandscapesSection.css';

function LandscapesSection() {
  return (
    <div className='landscapes-container'>
      <h1 className='landscapes'>Landscapes</h1>
      <img className='gallery-intro-photo' src='./images/Andrew/Maple_Pass_Andrew.jpg' />
      <h2 className='gallery'>Gallery</h2>
      <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hamakua_Chocolate_Farm_1582_D.jpg' >
        <img className='gallery-highlight-photo gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1582_D.jpg' />
      </a>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hilo_1700_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hilo_1700_D.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hamakua_Chocolate_Farm_1294.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1294.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Switzerland_Bernese_Highlands_336_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Switzerland_Bernese_Highlands_336_D.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Switzerland_Bernese_Highlands_330_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Switzerland_Bernese_Highlands_330_D.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_719.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_719.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_283.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_283.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Venice_574.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Venice_574.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Venice_538.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Venice_538.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Mt_Rainier_8_1_2022_300.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Mt_Rainier_8_1_2022_300.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Mt_Rainier_8_1_2022_257.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Mt_Rainier_8_1_2022_257.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Germany_Neuschwannstein_105.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Germany_Neuschwannstein_105.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Germany_Neuschwannstein_167.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Germany_Neuschwannstein_167.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Mt_Rainier_1.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Mt_Rainier_1.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Mt_Baker_1.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Mt_Baker_1.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_Maple_Pass_Blueberries.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_Maple_Pass_Blueberries.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_2_Cutthroat_Lake_80.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_2_Cutthroat_Lake_80.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2023_4_9_UW_Cherry_Blossoms_17.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2023_4_9_UW_Cherry_Blossoms_17.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2023_4_9_UW_Cherry_Blossoms_33-E.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2023_4_9_UW_Cherry_Blossoms_33-E.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_712.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_712.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_562.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_562.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hamakua_Chocolate_Farm_1548.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1548.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Beach_69_1606_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Beach_69_1606_D.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hamakua_Chocolate_Farm_1525.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1525.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hamakua_Chocolate_Farm_1539_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1539_D.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_624.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_624.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_604.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_604.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Olympics_2021_Landscapes_66.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Olympics_2021_Landscapes_66.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Baker_Lake_Deception_Pass_8_2_2022_3.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Baker_Lake_Deception_Pass_8_2_2022_3.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_21_Queeny_Park_151.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_21_Queeny_Park_151.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_28.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_28.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Cinque_Terre_Vernazza_1019.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Cinque_Terre_Vernazza_1019.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Amalfi_Coast_476.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Amalfi_Coast_476.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Cinque_Terre_Vernazza_924.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Cinque_Terre_Vernazza_924.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Cinque_Terre_Vernazza_974.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Cinque_Terre_Vernazza_974.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Venice_724.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Venice_724.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Italy_Venice_835.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Italy_Venice_835.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hilo_1687_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hilo_1687_D.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2023_Costa_Rica_Arenal_1968_Reserve_1731.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2023_Costa_Rica_Arenal_1968_Reserve_1731.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_21_Queeny_Park_60.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_21_Queeny_Park_60.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_21_Queeny_Park_122.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_21_Queeny_Park_122.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Switzerland_Bernese_Highlands_303.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Switzerland_Bernese_Highlands_303.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Switzerland_Bernese_Highlands_308_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Switzerland_Bernese_Highlands_308_D.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Germany_Hamburg_Speicherstadt_22.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Germany_Hamburg_Speicherstadt_22.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Europe_2021_Switzerland_Bernese_Highlands_264.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Europe_2021_Switzerland_Bernese_Highlands_264.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_732.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_732.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_756.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_756.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_15_Missouri_Botanical_Garden_160.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_15_Missouri_Botanical_Garden_160.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_15_Missouri_Botanical_Garden_233.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_15_Missouri_Botanical_Garden_233.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/Enchantments_2021_13.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Enchantments_2021_13.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Enchantments_2021_532.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Enchantments_2021_532.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hamakua_Chocolate_Farm_1406.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1406.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Hamakua_Chocolate_Farm_1460.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Hamakua_Chocolate_Farm_1460.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_103.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_103.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_224.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_224.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Punaluu_Beach_Coconut_261.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Punaluu_Beach_Coconut_261.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Tropical_Bioreserve_And_Garden_912.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Tropical_Bioreserve_And_Garden_912.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Waimea_1148_D.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Waimea_1148_D.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Waimea_1162.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Waimea_1162.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_691.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_691.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_702.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_702.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_510.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_510.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_471.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_471.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_571.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_571.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_680.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_680.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_87.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_87.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_552.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_552.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_8_Maple_Pass_Panorama.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_8_Maple_Pass_Panorama.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/Baker_Lake_Deception_Pass_8_2_2022_4_desmudged.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/Baker_Lake_Deception_Pass_8_2_2022_4_desmudged.jpg' />
        </a>
      </ul>
      <ul className='landscape-row'>
        <a target="_blank" href='./images/landscapes/full/2022_10_Hawaii_Waimea_1186.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_Hawaii_Waimea_1186.jpg' />
        </a>
        <a target="_blank" href='./images/landscapes/full/2022_10_15_Queeny_Park_31.jpg' >
          <img className='gallery-photo' src='./images/landscapes/thumbnails/2022_10_15_Queeny_Park_31.jpg' />
        </a>
      </ul>
    </div>
  );
}

export default LandscapesSection;

